import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Input, Output } from '@angular/core';
import { AccordionModule } from 'primeng/accordion';

@Component({
  standalone: true,
  imports: [CommonModule, AccordionModule],
  selector: 'ev-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AccordionComponent {
  /**
   * Input and output event as well, to control and listen to the open state of the accordion
   */
  @Input() isOpened = false;

  /**
   * Emitting a boolean value, when the accordion gets opened or closed
   */
  @Output() isOpenedChange = new EventEmitter<boolean>();

  public onIsOpenedChange(): void {
    this.isOpened = !this.isOpened;
    this.isOpenedChange.emit(this.isOpened);
  }
}
