import { AfterViewInit, Component, ElementRef, Input, OnDestroy, viewChild } from '@angular/core';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@Component({
  standalone: true,
  imports: [ProgressSpinnerModule],
  selector: 'ev-loading-overlay',
  templateUrl: './loading-overlay.component.html',
  styleUrls: ['./loading-overlay.component.scss'],
})
export class LoadingOverlayComponent implements AfterViewInit, OnDestroy {
  @Input() title = $localize`Loading...`;

  overlay = viewChild<ElementRef<HTMLElement>>('overlay');

  private bodyOverlayElement?: HTMLElement;

  ngAfterViewInit() {
    const el = this.overlay()?.nativeElement;
    if (el) {
      this.bodyOverlayElement = document.body.appendChild(el);
    }
  }

  ngOnDestroy() {
    this.bodyOverlayElement?.remove();
  }
}
